@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@use '@angular/material' as mat;

$t: mat.m2-define-typography-config($font-family: 'Titillium Web',
$headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.m2-define-typography-level(34px, 40px, 400, 'Titillium Web', normal),
$headline-5: mat.m2-define-typography-level(24px, 32px, 400, 'Titillium Web', normal),
$headline-6: mat.m2-define-typography-level(22px, 30px, 600, 'Titillium Web', normal),
$subtitle-1: mat.m2-define-typography-level(18px, 23.2px, 600, 'Titillium Web', normal),
$subtitle-2: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-1: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-2: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$caption: mat.m2-define-typography-level(12px, 18px, 400, 'Titillium Web', normal),
$button: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal));

@include spb.set-typography($t);

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: var.$spb-max-width;
  width: 80%;
}

.spb-filler {
  flex-grow: 1;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: var.$spb-small) {
  .spb-standard-full-width {
    padding: initial;
    width: 100%;
  }
}

a {
  color: #232323;
}

/**
Some extras for forms
 */
.spb-form {
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  * mat-form-field,
  mat-select,
  mat-checkbox {
    max-width: 300px;
  }
}

.spb-step-mongo {
  align-items: flex-start;
  display: flex;
}

.spb-step-info {
  align-self: flex-start;
  color: #484848;
  margin-left: 150px;
  max-width: 300px;
  position: sticky;
  top: 150px;
}

.spb-mongo-highlight {
  font-weight: bold;
}

// Disables all click events.
.disable-click-events {
  pointer-events: none;
}
